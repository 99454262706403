import React, { useCallback, useState } from 'react';
import { Thumbnail, Button, Card, Filters, ResourceItem, ResourceList, TextStyle, EmptySearchResult, Pagination } from '@shopify/polaris';
import { NavLink, useHistory } from 'react-router-dom';
import {Spinner} from '@shopify/polaris';

const axios = require('axios');

function Product() {
    let history = useHistory();
    let store_name = (new URLSearchParams(window.location.search)).get("store_name");
    let id = (new URLSearchParams(window.location.search)).get("variant_id");
    let since_id = (new URLSearchParams(window.location.search)).get("since_id");
    let bundle_list_id = (new URLSearchParams(window.location.search)).get("bundle_list_id");

    // let domain = "/lumen/";
    let domain = "https://bundles.quadro.world/shopify_project/public/"
    let base_url = domain + "GetShopifyProductCopyTest?store_name=" + store_name + "&since_id=" + since_id + "&variant_id=" + id;

    const [post, setPost] = React.useState(null);
    const [getLoading, setLoding] = React.useState(false);
    const [getPageLoading, setPageLoding] = React.useState(true);
    const [backlink, setbacklink] = React.useState(false);
    const [nextlink, setnextlink] = React.useState(true);
    const [saveBtn, setsaveBtn] = React.useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [queryValue, setQueryValue] = useState("");
    const handleQueryValueRemove = useCallback(() => { setQueryValue(""); setPost([]); }, []);
    const handleQueryValueChange = useCallback((value) => { setQueryValue(value); setPost([]); }, []);
    const handleClearAll = useCallback(() => {
        handleQueryValueRemove();
        setPost([]);
    }, [handleQueryValueRemove]);
    const [emptySearchResult, setEmptySearchResult] = React.useState(false);

    var next_url_pagination;
    var prev_url_pagination;
    
    const url = new URL(window.location.href);
    const getParams = url.searchParams;

    var page_title = '';
    var get_stored_page_title = localStorage.getItem('page_title')
    if(get_stored_page_title){
        page_title = get_stored_page_title;
    }
    else{
        if(getParams.get('title')){
            page_title = getParams.get('title').replaceAll('   ', ' + ');
        }
        if(getParams.get('variant_title')){
            var variant_title = getParams.get('variant_title');
        }

        page_title = page_title+' - '+variant_title+'';
        localStorage.setItem('page_title', page_title);
    }
    var prev_array = [];

    React.useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();

        var keyword = queryValue !== "" ? '&keyword='+queryValue : "";
        axios.get(base_url + keyword, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            cancelToken: source.token
        }).then((response) => {
            setPost(response.data.products);
            setPageLoding(false);
            if (since_id !== null && since_id !== '') {
                setbacklink(true);
            }
            var inputs = document.getElementsByClassName('Polaris-Checkbox__Input');
            for(var i = 0; i < inputs.length; i++) {
                inputs[i].disabled = false;
            }
        });

        return () => {
            source.cancel('Cancelled due to stale request');
        }
    }, [queryValue, handleQueryValueRemove]);


    var products = [];
    if (post !== null) {
        products = post.map(element => {
            return (element.product_type !== "Bundle") ? {
                id: element.handle + ":" + element.veriant_id,
                title: element.title,
                status: element.status,
                product_type: element.product_type,
                handle: element.handle,
                image: element.image,
                veriant_id: element.veriant_id,
                veriant_title: element.veriant_title,
                p_id: element.id,
                quantity_value: element.quantity == 0 ? 1 : element.quantity
            } : '';
        });

        products = products.filter(el => {
            return el != null && el != '';
        });
    }
    
    React.useEffect(() => {
        if (post != null) {
            setEmptySearchResult(false);
            if(post.length == 0){
                setTimeout(function() {setEmptySearchResult(true)}, 3000);
            }
            if (post.length < 20) {
                setnextlink(false);
            }
            else{
                setnextlink(true);
            }
            for ( var i = 0, len = localStorage.length; i < len; ++i ) {
                if(localStorage.key(i) == 'previousVal' || localStorage.key(i) == 'productPreviousVal' || localStorage.key(i) == 'previous_array' || localStorage.key(i) == 'final_array' || localStorage.key(i) == 'page_title'){}
                else{
                    if(document.getElementById(localStorage.key(i)+'-quantity')){
                        document.getElementById(localStorage.key(i)+'-quantity').value = parseInt(localStorage.getItem(localStorage.key(i)));
                    } 
                }
            }
        }
        setEmptySearchResult(false);
    }, [post]);

    

    React.useEffect(() => {
        var result = [];
        var productPreviousVal = localStorage.getItem('productPreviousVal');

        if(since_id == null && productPreviousVal == null){
            axios.get(domain + 'GetAssociateProduct?id=' + id + '&store_name=' + store_name, {
                headers: {
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            }).then((response) => {
                    result = response.data.map(function (a) {
                    prev_array.push(a.child_product_id);
                    localStorage.setItem(a.child_product_id, a.child_product_quantity);
                    return a.child_product_id;
                });
            
                for ( var i = 0, len = localStorage.length; i < len; ++i ) {
                    if(localStorage.key(i) == 'previousVal' || localStorage.key(i) == 'productPreviousVal' || localStorage.key(i) == 'previous_array' || localStorage.key(i) == 'final_array' || localStorage.key(i) == 'page_title'){}
                    else{
                        result.push(localStorage.key(i));
                    }
                }
                
                var uniqueResult = result.filter((value, index, self) => self.indexOf(value) === index);
                setSelectedItems(uniqueResult);
            });
        }
        else{
            for ( var i = 0, len = localStorage.length; i < len; ++i ) {
                if(localStorage.key(i) == 'previousVal' || localStorage.key(i) == 'productPreviousVal' || localStorage.key(i) == 'previous_array' || localStorage.key(i) == 'final_array' || localStorage.key(i) == 'page_title'){}
                else{
                    result.push(localStorage.key(i));
                }
            }
            
            var uniqueResult = result.filter((value, index, self) => self.indexOf(value) === index);
            setSelectedItems(uniqueResult);
        }
        
    }, []);

    let id_url = (new URLSearchParams(window.location.search)).get("id");
    let variant_id_url = (new URLSearchParams(window.location.search)).get("variant_id");
    let variant_title_url = (new URLSearchParams(window.location.search)).get("variant_title");

    const last_id = products[products.length - 1];
    console.log('check last id', products);

    if (last_id != null) {
        const last_ids = (last_id.p_id);
        next_url_pagination = "/product?id=" + id_url + "&variant_id=" + variant_id_url + "&variant_title=" + variant_title_url + "&store_name=" + store_name + "&since_id=" + (last_ids) + "&bundle_list_id=" + bundle_list_id;
        
        var urlValPrev = [];
        var urlValPrev = JSON.parse(localStorage.getItem('productPreviousVal'));
        if(urlValPrev){
            const since_id_val = urlValPrev[urlValPrev.length -1];
            if (typeof since_id_val === 'string'){ 
                prev_url_pagination = "/product?id=" + id_url + "&variant_id=" + variant_id_url + "&variant_title=" + variant_title_url + "&store_name=" + store_name + "&since_id=" + since_id_val + "&bundle_list_id=" + bundle_list_id;
            }
            else{
                prev_url_pagination = "/product?id=" + id_url + "&variant_id=" + variant_id_url + "&variant_title=" + variant_title_url + "&store_name=" + store_name + "&bundle_list_id=" + bundle_list_id;
            }
        }    
    }

    const emptyStateMarkup = (
        <>
        <div className={emptySearchResult === false ? 'show-div spinner-center' : 'hide-div spinner-center'}>
            <Spinner accessibilityLabel="Small spinner example" size="small" />
        </div>
        <div className={emptySearchResult === true ? 'show-div' : 'hide-div'}>
            <EmptySearchResult
                title={'No Items yet'}
                description={'Try changing the filters or search term'}
                withIllustration
            />
        </div>
        </>
    );

    function PreviousLinkArr(){
        var urlVal = JSON.parse(localStorage.getItem('productPreviousVal'));
        if(urlVal){
            urlVal.pop();
            localStorage.setItem('productPreviousVal',JSON.stringify(urlVal));
        }
    }

    function saveArray(){
        var saveArr = JSON.parse(localStorage.getItem('previous_array'));
        if(saveArr && saveArr.length > 0){
            var getFinalArr = JSON.parse(localStorage.getItem('final_array'));
            if(getFinalArr && getFinalArr.length > 0){
                
                for(let i = 0; i < saveArr.length; i++){
                    var get_val = localStorage.getItem(saveArr[i]);
                    getFinalArr.push([saveArr[i], get_val]);
                }
                localStorage.setItem('final_array', JSON.stringify(getFinalArr));
            }
            else{
                var finalArr = [];

                var unique = saveArr.filter((value, index, self) => self.indexOf(value) === index);

                for(let i = 0; i < unique.length; i++){
                    var get_val = localStorage.getItem(saveArr[i]);
                    finalArr.push([saveArr[i], get_val]);
                }
                localStorage.setItem('final_array', JSON.stringify(finalArr));
            }
            var prevArr = [];
            localStorage.setItem('previous_array', JSON.stringify(prevArr));
        }
    }

    function checkFinalArr(param){
        var arr = JSON.parse(localStorage.getItem('final_array'));
        if(arr){
            for(let i = 0; i < arr.length; i++){
                if(arr[i][0] == param){
                    let index = arr.indexOf(arr[i]);
                    arr.splice(index,1);
                }
            }
            localStorage.setItem('final_array', JSON.stringify(arr));
            var prevArr = [];
            localStorage.setItem('previous_array', JSON.stringify(prevArr));
        }
    }

    let evt = [];

    function setValues(evt){
        setsaveBtn(false);
        setSelectedItems(evt);
    
        let storage_array = [];
        for (var i = 0, len = localStorage.length; i < len; ++i) {
            if(localStorage.key(i) == 'previousVal' || localStorage.key(i) == 'productPreviousVal' || localStorage.key(i) == 'previous_array' || localStorage.key(i) == 'final_array' || localStorage.key(i) == 'page_title'){}
            else{
                storage_array.push(localStorage.key(i));
            }
        }
    
        let difference = evt
            .filter(x => !storage_array.includes(x))
            .concat(storage_array.filter(x => !evt.includes(x)));
            
        for (var j = 0; j < difference.length; ++j) {
            if(document.getElementById(difference[j])){
                localStorage.removeItem(difference[j]);
            }
        }
        
        for(let k = 0; k < evt.length; k++){
            var quan = getQuantity(evt[k]);
            if(quan != 0){
                localStorage.setItem(evt[k], quan);
            }
        }
    }


    function getQuantity(param){
        var quantity = document.getElementById(param+'-quantity');
        if(quantity){
            return document.getElementById(param+'-quantity').value;
        }
        else{
            return 0;
        } 
    }
    
    var urlArray = [];
    function NextLinkArr(){
        var urlVal = JSON.parse(localStorage.getItem('productPreviousVal'));
        if(urlVal){
            urlVal.push(since_id);
            localStorage.setItem('productPreviousVal',JSON.stringify(urlVal));
        }
        else{
            urlArray.push(since_id);
            localStorage.setItem('productPreviousVal',JSON.stringify(urlArray));
        }
    }

    function stopClick(event){
        event.stopPropagation();
    }

    function getInputVal(param, param1){
        var input = document.getElementById(param);
        var localQuan = localStorage.getItem(param1);
	    if(input && input.value.length == 0){
            input.setAttribute('value', 0);
            if(localQuan){
                localStorage.setItem(param1,0)
            }
        }
        else if(input){
            input.setAttribute('value', input.value);
            if(localQuan){
                localStorage.setItem(param1,input.value)
            }
        }
    }

    const filters = [];
    let href_val = bundle_list_id === 'null' || bundle_list_id === null ? '' : '&since_id=' + bundle_list_id ;
    const filterControl = (
        <Filters
            queryValue={queryValue}
            filters={filters}
            onQueryChange={handleQueryValueChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
        >

            <div style={{ paddingLeft: '8px' }}>
                <Button disabled={saveBtn} loading={getLoading} onClick={() => addEmptyItem()}>Save</Button>
                <a activeClassName="is-active" className="navbar-item" href={`/?store_name=` + store_name + href_val }>
                    <Button primary>Back</Button>
                </a>
            </div>
        </Filters>
    );

    const handleInitialSelection = useCallback(
        (id) => () => {
            var list_arr = JSON.parse(localStorage.getItem('previous_array'));
            if(list_arr){
                list_arr.push(id);
                localStorage.setItem('previous_array', JSON.stringify(list_arr));
            }
            else{
                let list_arr = [];
                list_arr.push(id);
                localStorage.setItem('previous_array', JSON.stringify(list_arr));
            }
            var quan = getQuantity(id);
            if(quan !== 0){
                localStorage.setItem(id, quan);
            }
            setSelectedItems([products.find((t) => t.id === id).id]);
        },
        [selectedItems, products]
    );

    return (
        <Card>
            <h2 className='page-title'>{ page_title }</h2>
            <ResourceList
                resourceName={{ singular: 'Items', plural: 'Items' }}
                items={products}
                renderItem={renderItem}
                selectedItems={selectedItems}
                onSelectionChange={setValues}
                selectable
                filterControl={filterControl}
                loading={getPageLoading}
                emptyState={emptyStateMarkup}
            />

            <div style={{ height: '100px', marginLeft: '50%', marginRight: '50%' }}>
                <Pagination previousKeys={[74]} previousTooltip="Previous (J)" onPrevious={() => {
                        saveArray();
                        PreviousLinkArr();
                    }} nextKeys={[75]} nextTooltip="Next (K)" onNext={() => {
                        saveArray();
                        NextLinkArr();
                    }} nextURL={next_url_pagination} previousURL={prev_url_pagination} hasNext={nextlink} hasPrevious={backlink} 
                />
            </div>
        </Card>
    );

    function renderItem(item) {
        const { handle, title, status, product_type, image, veriant_id, veriant_title, quantity_value } = item;
        const media = <Thumbnail source={image} alt={veriant_title} />;
        return (
            <ResourceItem
                id={handle + ":" + veriant_id}
                url=""
                media={media}
                accessibilityLabel={`View details for ${title}`}
                onClick={handleInitialSelection(handle + ":" + veriant_id)}>
                <h3>
                    <TextStyle variation="strong">{title} - {veriant_title}</TextStyle>
                </h3>
                <div>{status}</div>
                <div>{product_type}</div>
                <div>{handle}</div>
                <div className="Polaris__custom_field_has-value">
                    <input min="1" type="number" onClick={event => stopClick(event)} onChange={() => { getInputVal(handle + ":" + veriant_id + "-quantity",handle + ":" + veriant_id) }} id={handle + ":" + veriant_id + "-quantity"} className="Polaris__custom_field" 
                        defaultValue={quantity_value} />
                </div>
            </ResourceItem>
        );
    }

    function checkUnique(param, param1){
        if(param1.length == 0){
            return 1;
        }
        for(let j = 0; j < param1.length; j++){
            if(param1[j][0] == param){
                return 0;
            }
        }
        return 1;
    }

    function getUnique(param){
        var new_arr = [];
        for(let i = 0; i < param.length; i++){
            if(checkUnique(param[i][0], new_arr) == 1){
                new_arr.push(param[i]);
            }
        }
        return new_arr;
    }

    function addEmptyItem() {
        setsaveBtn(true);
        setLoding(true);
        let id = (new URLSearchParams(window.location.search)).get("id");
        let variant_id = (new URLSearchParams(window.location.search)).get("variant_id");
        let variant_title = (new URLSearchParams(window.location.search)).get("variant_title");
        var array = []

        for ( var i = 0, len = localStorage.length; i < len; ++i ) {
            if(localStorage.key(i) == 'previousVal' || localStorage.key(i) == 'productPreviousVal' || localStorage.key(i) == 'previous_array' || localStorage.key(i) == 'final_array' || localStorage.key(i) == 'page_title'){}
            else{
                array.push([localStorage.key(i), localStorage.getItem(localStorage.key(i))]);
            }
        }

        var postData = {
            parant_product_id: id,
            arr: array,
            store_name: store_name,
            variant_id: variant_id,
            variant_title: variant_title
        };


        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };


        axios.post(domain + 'SaveProduct', postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                setLoding(false);
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })
    }
}

export default Product;