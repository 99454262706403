import React, { useCallback, useState } from 'react';
import { useIndexResourceState, Card, Filters, IndexTable, TextStyle, Pagination, EmptySearchResult } from '@shopify/polaris';
import { Switch, Route, NavLink, useHistory } from 'react-router-dom';
import Product from './Product';
const axios = require('axios');

function SimpleIndexTableExample() {
    let history = useHistory();
    let store_name = (new URLSearchParams(window.location.search)).get("store_name");
    var since_id = null;
    since_id = (new URLSearchParams(window.location.search)).get("since_id");
    const [post, setPost] = React.useState(null);
    const [backlink, setbacklink] = React.useState(false);
    const [nextlink, setnextlink] = React.useState(true);
    const [getPageLoading, setPageLoding] = React.useState(true);
    const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(products);
    

    const [queryValue, setQueryValue] = useState("");
    const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
    const handleQueryValueChange = useCallback((value) => setQueryValue(value), []);
    const handleClearAll = useCallback(() => {
        handleQueryValueRemove();
    }, [handleQueryValueRemove]);
    
    
    // var domain = "/lumen/";
    var base_url = ""
    var domain = "https://bundles.quadro.world/shopify_project/public/"
    
    if (since_id) {
        base_url = domain + "GetShopifyProductCopyTest?product_type=bundle&store_name=" + store_name + "&since_id=" + since_id;
    } else {
        base_url = domain + "GetShopifyProductCopyTest?product_type=bundle&store_name=" + store_name;
    }
    

    React.useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();
        
        setPageLoding(true);
        var keyword = queryValue !== "" ? '&keyword='+queryValue.replaceAll("+", "%2B") : "";
        axios.get(base_url + keyword, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            cancelToken: source.token
        }).then((response) => {
            setTimeout(() => {
                setPost(response.data.products);
                setPageLoding(false);
            }, 2000);

            if (since_id !== null && since_id !== '') {
                setbacklink(true);
            }
        }).catch((err) => {});

        return () => {
            source.cancel('Cancelled due to stale request');
        }
       
    }, [queryValue, handleClearAll]);

    var products = [];
    if (post !== null) {
        products = post.map(element => {
            return element;
        });
    }
    React.useEffect(() => {
        if (post != null) {
            if (post.length < 20) {
                setnextlink(false);
            }
            else{
                setnextlink(true);
            }
        }
    });

    function PreviousLinkArr(){
        var urlVal = JSON.parse(localStorage.getItem('previousVal'));
        if(urlVal){
            urlVal.pop();
            localStorage.setItem('previousVal',JSON.stringify(urlVal));
        }
    }

    var urlArray = [];
    var prev_url_pagination;
    function NextLinkArr(){
        var urlVal = JSON.parse(localStorage.getItem('previousVal'));
        if(urlVal){
            urlVal.push(since_id);
            localStorage.setItem('previousVal',JSON.stringify(urlVal));
        }
        else{
            urlArray.push(since_id);
            localStorage.setItem('previousVal',JSON.stringify(urlArray));
        }
    }


    const last_id = products[products.length - 1];
    var next_url_pagination;
    var prev_url_pagination;
    if (last_id != null) {
        const last_ids = (last_id.id);
        next_url_pagination = "?store_name=" + store_name + "&since_id=" + (last_ids);

        var urlValPrev = [];
        var urlValPrev = JSON.parse(localStorage.getItem('previousVal'));
        if(urlValPrev){
            const since_id_val = urlValPrev[urlValPrev.length -1];
            if (typeof since_id_val === 'string'){ 
                prev_url_pagination = "?store_name=" + store_name + "&since_id=" + since_id_val;
            }
            else{
                prev_url_pagination = "?store_name=" + store_name;
            }
        }  
    }

    function removeLocalStorage(){
        var myItem = localStorage.getItem('previousVal');
        localStorage.clear();
        localStorage.setItem('previousVal',myItem);
    }


    const resourceName = {
        singular: 'Product',
        plural: 'Products',
    };

    const emptyStateMarkup = (
        <EmptySearchResult/>
    );
    

    const filters = [];
    const rowMarkup = products.map(
        ({ id, title, status, product_type, veriant_title, veriant_id, quantity }, index) => (
            <IndexTable.Row
                id={veriant_id}
                key={veriant_id}
                selected={selectedResources.includes(id)}
                position={index}>

                <IndexTable.Cell>
                    <TextStyle variation="strong">{veriant_id}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <TextStyle variation="strong">{title}</TextStyle>
                    <TextStyle variation="strong"> - {veriant_title}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <TextStyle variation="">{product_type}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <TextStyle variation="">
                        <p className={quantity == 0 ? 'red' : ''}>{quantity}</p>
                    </TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <TextStyle variation="">
                        <NavLink
                            className="navbar-item"
                            activeClassName="is-active"
                            onClick={removeLocalStorage}
                            to={`/product?` + `id=${id}&title=${title}&store_name=${store_name}&variant_id=${veriant_id}&variant_title=${veriant_title}&bundle_list_id=${since_id}`}>
                            View
                        </NavLink>
                    </TextStyle>
                </IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    return (
        <Switch>
            <Route exact path="/product" component={Product}></Route>
            <Card>
                <div style={{ padding: '16px', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <Filters
                            queryValue={queryValue}
                            filters={filters}
                            onQueryChange={handleQueryValueChange}
                            onQueryClear={handleQueryValueRemove}
                            onClearAll={handleClearAll}
                        />
                    </div>
                </div>
                <IndexTable
                    resourceName={resourceName}
                    itemCount={products.length}
                    selectedItemsCount={
                        allResourcesSelected ? 'All' : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    loading={getPageLoading}
                    emptyState={emptyStateMarkup}
                    headings={[
                        { title: 'ID' },
                        { title: 'Title' },
                        { title: 'Product Type' },
                        { title: 'Quantity' },
                        { title: 'Action' },

                    ]}
                >
                    {rowMarkup}
                </IndexTable>
                <div style={{ height: '150px', marginLeft: '50%', marginRight: '50%' }}>
                    <Pagination previousKeys={[74]} previousTooltip="Previous (J)" onPrevious={() => {
                        console.log('Previous');
                        PreviousLinkArr();
                    }} nextKeys={[75]} nextTooltip="Next (K)" onNext={() => {
                        console.log('Next');
                        NextLinkArr();
                    }} nextURL={next_url_pagination} previousURL={prev_url_pagination} hasNext={nextlink} hasPrevious={backlink} />
                </div>
            </Card>
        </Switch>
    );
}

export default SimpleIndexTableExample;


